var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"rgba(0,0,0,0.4)","overlay-opacity":"0.8","width":"1240px","max-width":"1240px","scrollable":"","persistent":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.makeOrder)}}},[_c('v-card',{ref:"form",attrs:{"flat":""}},[_c('v-card-title',[_c('span',{staticClass:"font-weight-bold blue-grey--text font-size-lg ft ml-1"},[_vm._v("Create New Order")]),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',[_vm._l((_vm.orderLineItem),function(lineItem,index){return _c('v-col',{key:index},[_c('v-row',{staticClass:"px-2 pt-6 d-flex flex-row flex-grow-1",staticStyle:{"background":"#f7f7f7","border-radius":"8px"}},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","autofocus":"","label":"Branches","items":_vm.branches,"item-text":"name","item-value":"id","error-messages":errors[0],"hint":"Name of the branch"},model:{value:(lineItem.branchId),callback:function ($$v) {_vm.$set(lineItem, "branchId", $$v)},expression:"lineItem.branchId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","autofocus":"","items":_vm.products,"item-value":"id","item-text":"name","label":"Product","error-messages":errors[0],"hint":"Name of the product"},model:{value:(lineItem.productId),callback:function ($$v) {_vm.$set(lineItem, "productId", $$v)},expression:"lineItem.productId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","autofocus":"","label":"Quantity (Litres)","error-messages":errors[0],"hint":"Quantity Of Product"},model:{value:(lineItem.quantity),callback:function ($$v) {_vm.$set(lineItem, "quantity", $$v)},expression:"lineItem.quantity"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","autofocus":"","label":"Cheque Number","error-messages":errors[0],"hint":"Cheque number"},model:{value:(lineItem.chequeNumber),callback:function ($$v) {_vm.$set(lineItem, "chequeNumber", $$v)},expression:"lineItem.chequeNumber"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","autofocus":"","label":"Cheque Amount","error-messages":errors[0],"hint":"Amount on cheque"},model:{value:(lineItem.chequeAmount),callback:function ($$v) {_vm.$set(lineItem, "chequeAmount", $$v)},expression:"lineItem.chequeAmount"}})]}}],null,true)})],1),(_vm.orderLineItem.length > 1)?_c('v-row',{staticClass:"mt-2"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{staticClass:"ft font-size-sm",attrs:{"text":"","small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeOrderLineItem(index)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("remove")]),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v("Remove")])])],1)]):_vm._e()],1)}),_c('v-col',[_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addOrderLineItem($event)}}},[_c('i',{staticClass:"material-icons ml-n1"},[_vm._v("add")]),_c('span',{staticClass:"font-weight-medium ft "},[_vm._v(" New Row")])])],1)])],2),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 ft text-capitalize",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ft white--text text-capitalize",attrs:{"color":"primary","loading":_vm.loading,"type":"submit"}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }