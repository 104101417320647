<template>
  <v-dialog
    overlay-color="rgba(0,0,0,0.4)"
    overlay-opacity="0.8"
    v-model="state"
    width="1240px"
    max-width="1240px"
    scrollable
    persistent
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(makeOrder)" class="px-3">
        <v-card flat ref="form">
          <v-card-title>
            <span class="font-weight-bold blue-grey--text font-size-lg ft ml-1"
              >Create New Order</span
            >
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-col v-for="(lineItem, index) in orderLineItem" :key="index">
              <v-row
                class="px-2 pt-6 d-flex flex-row flex-grow-1"
                style="background: #f7f7f7;border-radius: 8px;"
              >
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-autocomplete
                    outlined
                    autofocus
                    label="Branches"
                    class="ft font-weight-medium pa-1"
                    :items="branches"
                    v-model="lineItem.branchId"
                    item-text="name"
                    item-value="id"
                    :error-messages="errors[0]"
                    hint="Name of the branch"
                  />
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-autocomplete
                    outlined
                    autofocus
                    v-model="lineItem.productId"
                    class="ft font-weight-medium pa-1"
                    :items="products"
                    item-value="id"
                    item-text="name"
                    label="Product"
                    :error-messages="errors[0]"
                    hint="Name of the product"
                  />
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required|numeric"
                >
                  <v-text-field
                    outlined
                    autofocus
                    v-model="lineItem.quantity"
                    label="Quantity (Litres)"
                    class="ft font-weight-medium pa-1"
                    :error-messages="errors[0]"
                    hint="Quantity Of Product"
                  />
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    autofocus
                    v-model="lineItem.chequeNumber"
                    class="ft font-weight-medium pa-1"
                    label="Cheque Number"
                    :error-messages="errors[0]"
                    hint="Cheque number"
                  />
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required|decimals"
                >
                  <v-text-field
                    outlined
                    autofocus
                    v-model="lineItem.chequeAmount"
                    class="ft font-weight-medium pa-1"
                    label="Cheque Amount"
                    :error-messages="errors[0]"
                    hint="Amount on cheque"
                  />
                </ValidationProvider>
              </v-row>
              <v-row class="mt-2" v-if="orderLineItem.length > 1">
                <div
                  class="d-flex flex-row flex-grow-1"
                  style="justify-content: flex-end;"
                >
                  <v-btn
                    @click.stop="removeOrderLineItem(index)"
                    text
                    small
                    color="red"
                    class="ft font-size-sm"
                  >
                    <i class="material-icons">remove</i
                    ><span style="font-size: 13px;">Remove</span>
                  </v-btn>
                </div>
              </v-row>
            </v-col>
            <v-col>
              <div class="d-flex flex-row flex-grow-1 justify-end">
                <v-btn text @click.stop="addOrderLineItem" color="primary">
                  <i class="material-icons ml-n1">add</i>
                  <span class="font-weight-medium ft "> New Row</span>
                </v-btn>
              </div>
            </v-col>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="mr-2 ft text-capitalize" text @click="close">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              type="submit"
              class="ft white--text text-capitalize"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { mapGetters } from "vuex";
import { required, numeric } from "vee-validate/dist/rules";
import { decimals } from "../../../../utils/resolvers";

extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
extend("decimals", decimals);
export default {
  name: "NewOrderDialog",
  props: {
    state: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    order: {},
    orderLineItem: [
      {
        quantity: 0,
        productId: null,
        branchId: null,
        chequeNumber: null,
        chequeAmount: 0
      }
    ]
  }),
  computed: {
    ...mapGetters({
      branches: "orders/getAllBranches",
      products: "orders/getAllProducts"
    })
  },
  methods: {
    addOrderLineItem() {
      this.orderLineItem.push({
        quantity: 0,
        productId: null,
        branchId: null,
        chequeNumber: null,
        chequeAmount: 0
      });
    },
    removeOrderLineItem(index) {
      this.orderLineItem.splice(index, 1);
    },
    makeOrder() {
      this.$emit("order", {
        loading: { idx: "add", status: false },
        body: {
          orderLineItem: this.orderLineItem
        }
      });
    },
    close() {
      this.$emit("actions", "add");
    }
  }
};
</script>
